<template>
	<div>
		<div
			v-for="(item, i) in items"
			:class="`font-size-14 d-inline-block white--text background-${target}`"
			:key="i"
			:style="{
				height: calculateWidthColumn(1),
				'line-height': calculateWidthColumn(1),
			}"
		>
			<div
				:class="{ 'd-inline-block': true, 'font-weight-bold': i === items.length - 1, item: true }"
				@click="onItemClick(item)"
			>
				{{ item.text.toUpperCase() }}
			</div>
			<v-icon class="pa-2 d-inline-block" v-if="i !== items.length - 1" size="6" color="white">mdi-circle</v-icon>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		items: {
			type: Array,
			required: true,
		},
		target: {
			type: String,
			required: true,
		},
	},
	methods: {
		onItemClick(item) {
			if (!item.to) {
				this.$router.push({
					name: "Archives",
				});
			} else {
				this.$router.push({
					name: "Archives",
					params: { category: item.to },
				});
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.item {
	cursor: pointer;
}
</style>
