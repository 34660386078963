var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"wrapper",style:({
		height: _vm.$vuetify.breakpoint.mobile
			? _vm.calculateWidthColumn(4)
			: _vm.calculateWidthColumn(5),
	})},[_c('h1',{class:'font-size-100 background-' + _vm.target,style:({
			'background-color': 'transparent',
			'line-height': _vm.calculateWidthColumn(2),
			'margin-bottom': _vm.$vuetify.breakpoint.mobile
				? 0
				: _vm.calculateWidthColumn(1),
			'margin-top': _vm.calculateWidthColumn(2),
			color: _vm.color,
			width: _vm.calculateWidthColumn(_vm.titleWidthColumn),
		})},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.dotsNumberX > _vm.mainColumnWidth + 1)?_c('MovingLogo',{attrs:{"color":_vm.color || 'black',"offset":_vm.mainColumnWidth - _vm.titleWidthColumn - 10,"target":_vm.target,"width":_vm.mainColumnWidth - _vm.titleWidthColumn}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }