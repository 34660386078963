<template>
	<v-row
		class="wrapper"
		:style="{
			height: $vuetify.breakpoint.mobile
				? calculateWidthColumn(4)
				: calculateWidthColumn(5),
		}"
	>
		<h1
			:class="'font-size-100 background-' + target"
			:style="{
				'background-color': 'transparent',
				'line-height': calculateWidthColumn(2),
				'margin-bottom': $vuetify.breakpoint.mobile
					? 0
					: calculateWidthColumn(1),
				'margin-top': calculateWidthColumn(2),
				color: color,
				width: calculateWidthColumn(titleWidthColumn),
			}"
		>
			{{ title }}
		</h1>
		<MovingLogo
			v-if="dotsNumberX > mainColumnWidth + 1"
			:color="color || 'black'"
			:offset="mainColumnWidth - titleWidthColumn - 10"
			:target="target"
			:width="mainColumnWidth - titleWidthColumn"
		/>
	</v-row>
</template>

<script>
import MovingLogo from "@/components/ui/MovingLogo.vue";
export default {
	components: {
		MovingLogo,
	},
	data() {
		return {
			titleWidthColumn: null,
		};
	},
	props: {
		backgroundColor: {
			type: String,
			required: false,
		},
		color: {
			type: String,
			required: false,
		},
		offset: {
			type: Number,
			required: false,
			default: 0,
		},
		title: {
			type: String,
			required: true,
		},
		titleWidth: {
			type: Number,
			required: false,
		},
		target: {
			type: String,
			required: false,
		},
	},
	mounted() {
		if (!this.titleWidth) {
			this.titleWidthColumn = this.calculateTextWidth();
		} else {
			this.titleWidthColumn = this.titleWidth;
		}
		window.addEventListener("resize", () => {
			this.titleWidthColumn = this.calculateTextWidth();
		});
	},
	methods: {
		calculateTextWidth() {
			let textWidth = this.getTextWidth("6.25rem", this.title);
			return Math.ceil(textWidth / this.columnWidth);
		},
	},
};
</script>

<style lang="scss" scoped>
.background-default {
	background-color: white;
}
.wrapper {
	position: relative;
	pointer-events: none;
}
@media only screen and (max-width: 768px) {
	h1 {
		max-width: 90%;
	}
}
</style>
