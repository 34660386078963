<template>
	<Background
		v-if="formattedData"
		color="rgba(255,255,255,0.3)"
		:target="formattedData.target.slug"
		:spacingTop="true"
	>
		<div class="page">
			<MainColumn>
				<Title
					color="white"
					:offset="0"
					:title="
						category == 'novita' ? 'Novità' : category | capitalize
					"
					:target="formattedData.target.slug"
				/>
				<Breadcrumbs
					:target="formattedData.target.slug"
					:items="breadcrumbItems"
				/>
				<v-card class="card" tile v-if="formattedData">
					<Review
						v-if="reviewSlugs.indexOf(category) > -1"
						:postSlug="postSlug"
					/>
					<Article
						v-if="articleSlugs.indexOf(category) > -1"
						:postSlug="postSlug"
					/>
					<Reportage
						v-if="reportageSlugs.indexOf(category) > -1"
						:postSlug="postSlug"
					/>
					<Announcement
						v-if="announcementSlugs.indexOf(category) > -1"
						:postSlug="postSlug"
					/>
					<v-row class="content">
						<v-col offset="2" cols="8">
							<RenderBlocks
								:blocks="formattedData.blocks"
								:padding="true"
							/>
						</v-col>
					</v-row>
				</v-card>
			</MainColumn>
		</div>
	</Background>
</template>

<script>
import Background from "@/components/ui/Background";
import Breadcrumbs from "@/components/ui/Breadcrumbs";
import RenderBlocks from "@/components/blocks/RenderBlocks";
import Title from "@/components/ui/Title";
import { formatCategoriesAndTargets } from "@/mixins/formatCategoriesAndTargets";
import { GET_POST, GET_POST_PREVIEW } from "@/queries/Posts.js";
export default {
	metaInfo() {
		if (this.seo) {
			return {
				title: this.seo.title,
				meta: [
					{
						name: "description",
						content: this.seo.metaDesc
					},
					{
						name: "keywords",
						content: this.seo.metaKeywords
							? this.seo.metaKeywords
							: this.defaultMetaKeywords
					},
					{
						name: "author",
						content: this.seo.opengraphSiteName
					},
					{
						property: "og:title",
						content: this.seo.title
					},
					{
						property: "og:type",
						content: this.seo.opengraphType
					},
					{
						property: "og:url",
						content: this.seo.opengraphUrl
					},
					{
						property: "og:description",
						content: this.seo.metaDesc
					},
					{
						property: "og:image",
						content: this.seo.opengraphImage
							? this.seo.opengraphImage.sourceUrl
							: this.defaultUrlImage
					},
					{ name: "robots", content: "index,follow" }
				]
			};
		}
	},
	components: {
		Review: () => import("@/views/News/Review"),
		Article: () => import("@/views/News/Article"),
		Announcement: () => import("@/views/News/Announcement"),
		Reportage: () => import("@/views/News/Reportage"),
		Background,
		Breadcrumbs,
		RenderBlocks,
		Title
	},
	mixins: [formatCategoriesAndTargets],

	computed: {
		pageColor() {
			return this.colorsObject[this.formattedData.target.slug];
		}
	},
	data() {
		return {
			seo: false,
			breadcrumbItems: [],
			infoArray: [
				{ label: "year", key: "year" },
				{ label: "publisher", key: "publisher" },
				{ label: "genre", key: "tags" },
				{ label: "suitedFor", key: "suitedFor" }
			],
			formattedData: null,
			postSlug: null,
			category: null
		};
	},
	filters: {
		addComma: function(value, key) {
			if (key !== "tags") {
				return value;
			}
			let message = "";
			for (var i = 0; i < value.length; i++) {
				if (i !== value.length - 1) {
					message += value[i].name + ", ";
				} else {
					message += value[i].name;
				}
			}
			return message;
		}
	},
	async mounted() {
		this.loadStart();
		this.category = this.$route.params.category;
		this.postSlug = this.$route.params.postSlug;

		if (
			this.announcementSlugs.includes(this.postSlug) ||
			this.reportageSlugs.includes(this.postSlug) ||
			this.reviewSlugs.includes(this.postSlug)
		) {
			this.$router.push({
				name: "Archives",
				params: { category: this.category, target: this.postSlug }
			});
			return;
		}

		let query;
		let isPreview = this.$route.query && this.$route.query.preview;
		if (isPreview) {
			query = {
				query: GET_POST_PREVIEW(this.postSlug)
			};
		} else {
			query = {
				query: GET_POST(this.postSlug)
			};
		}
		
        let data = await this.$apollo.query(query);

        if (isPreview) {
            data = data && data.data && data.data.post || false;
        } else {
            data = data && data.data && data.data.posts && data.data.posts.nodes && data.data.posts.nodes[0]? data.data.posts.nodes[0] : false;
        }
		if (data) {
			this.seo = data.seo || null;
			data.blocks = JSON.parse("[" + data.blocksJSON + "]")[0];
			let newData = this.formatCategoriesAndTargets(data)[0];
			this.formattedData = {
				title: newData.title,
				blocks: newData.blocks.slice(1, newData.length),
				srcImage: newData.featuredImage
					? newData.featuredImage.node.sourceUrl
					: "",
				target: newData.target,
				subCategory: newData.subCategory,
				mainCategory: newData.mainCategory
			};
			this.breadcrumbItems = [
				{
					text: "Notizie",
					to: null
				},
				{
					text: this.formattedData.mainCategory.name,
					to: this.formattedData.mainCategory.slug
				}
			];
			this.loadDone();
		} else {
			this.get404();
		}
	}
};
</script>

<style lang="scss" scoped>
.content {
	padding: $spacing-1 0;
	@media only screen and (max-width: 768px) {
		padding: $spacing-0 0;
	}
}
.page {
	margin: auto;
	margin-bottom: $spacing-1;
}
.card {
	min-height: 1000px;
}
.reviewCard {
	margin-right: 50px;
	width: 70%;
}

.paddingRow {
	padding-top: 100px;
	padding: 3% 3% 3%;
}
.quote {
	padding: 55px;
	border-radius: 50px 50px 0px 50px;
	word-break: break-word;
}
.author {
	color: $green;
}
@media only screen and (max-width: 768px) {
	.reviewCard {
		width: 100%;
	}

	.row {
		display: inline-block;
		width: 100%;
	}

	.col {
		width: 90%;
		max-width: 90%;
		margin: auto;
	}
}

@media only screen and (max-width: 550px) {
	.card {
		padding: 0 $spacing-0;
		max-width: 100vw;
		left: 50%;
		position: relative;
		transform: translateX(-50%);
	}
}
</style>
